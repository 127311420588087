import {
  Avatar,
  Card,
  CardBody,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import Link from "next/link";
import { BiLogoGoLang } from "react-icons/bi";
import { FaPhp, FaPython } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { SiFlutter, SiRuby, SiTypescript } from "react-icons/si";

const langs = [
  {
    label: "Javascript",
    icon: <IoLogoJavascript fontSize="1.5rem" />,
    bg: "yellow.400",
    code: "javascript",
  },
  {
    label: "Typescript",
    icon: <SiTypescript fontSize="1.5rem" />,
    bg: "blue.500",
    code: "typescript",
  },
  {
    label: "Go",
    icon: <BiLogoGoLang fontSize="2rem" />,
    bg: "cyan.500",
    code: "go",
  },
  {
    label: "PHP",
    icon: <FaPhp fontSize="2rem" />,
    bg: "blue.400",
    code: "php",
  },
  {
    label: "Python",
    icon: <FaPython fontSize="1.8rem" />,
    bg: "blue.600",
    code: "python",
  },
  {
    label: "Dart",
    icon: <SiFlutter fontSize="1.5rem" />,
    bg: "cyan.400",
    code: "dart",
  },
  {
    label: "Ruby",
    icon: <SiRuby fontSize="1.5rem" />,
    bg: "red.600",
    code: "ruby",
  },
];

const LanguageList = () => {
  return (
    <>
      <SimpleGrid columns={{sm:7,base:3}} gap={4}>
        {langs.map((item) => (
          <Link href={`/playground/compiler/${item.code}`}>
            <Card key={item.label} rounded={"xl"}>
              <CardBody p={2} cursor={"pointer"} _hover={{ opacity: 0.6 }}>
                <VStack>
                  <Avatar bg={item.bg} icon={item.icon} />
                  <Text color={"gray.600"}>{item.label}</Text>
                </VStack>
              </CardBody>
            </Card>
          </Link>
        ))}
      </SimpleGrid>
    </>
  );
};

const LanguageListSmallDark = () => {
  return (
    <>
      <SimpleGrid columns={8} gap={4}>
        {langs.map((item) => (
          <Link href={`/playground/compiler/${item.code}`}>
            <Card key={item.label} rounded={"xl"} bg={"gray.700"} _hover={{bg:"blackAlpha.800"}}>
              <CardBody p={2} cursor={"pointer"}>
                <VStack spacing={0}>
                  <Avatar bg={item.bg} icon={item.icon} />
                  <Text color={"gray.300"}>{item.label}</Text>
                </VStack>
              </CardBody>
            </Card>
          </Link>
        ))}
      </SimpleGrid>
    </>
  );
};

export {LanguageListSmallDark}

export default LanguageList;
